import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserPersonalInformation from './user-personal-information';
import UserPersonalInformationDetail from './user-personal-information-detail';
import UserPersonalInformationUpdate from './user-personal-information-update';
import UserPersonalInformationDeleteDialog from './user-personal-information-delete-dialog';

const UserPersonalInformationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserPersonalInformation />} />
    <Route path="new" element={<UserPersonalInformationUpdate />} />
    <Route path=":id">
      <Route index element={<UserPersonalInformationDetail />} />
      <Route path="edit" element={<UserPersonalInformationUpdate />} />
      <Route path="delete" element={<UserPersonalInformationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserPersonalInformationRoutes;
