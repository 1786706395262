import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './account-representatives.reducer';

export const AccountRepresentativesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const accountRepresentativesEntity = useAppSelector(state => state.accountRepresentatives.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="accountRepresentativesDetailsHeading">
          <Translate contentKey="nylabankapiApp.accountRepresentatives.detail.title">AccountRepresentatives</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.id">Id</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.id}</dd>
          <dt>
            <span id="fullName">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.fullName">Full Name</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.fullName}</dd>
          <dt>
            <span id="position">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.position">Position</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.position}</dd>
          <dt>
            <span id="organizationEmail">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.organizationEmail">Organization Email</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.organizationEmail}</dd>
          <dt>
            <span id="isCardVerified">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.isCardVerified">Is Card Verified</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.isCardVerified ? 'true' : 'false'}</dd>
          <dt>
            <span id="isCardUploaded">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.isCardUploaded">Is Card Uploaded</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.isCardUploaded ? 'true' : 'false'}</dd>
          <dt>
            <span id="isLiveFacialMatching">
              <Translate contentKey="nylabankapiApp.accountRepresentatives.isLiveFacialMatching">Is Live Facial Matching</Translate>
            </span>
          </dt>
          <dd>{accountRepresentativesEntity.isLiveFacialMatching ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/account-representatives" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/account-representatives/${accountRepresentativesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AccountRepresentativesDetail;
