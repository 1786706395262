import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-personal-information.reducer';

export const UserPersonalInformationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userPersonalInformationEntity = useAppSelector(state => state.userPersonalInformation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userPersonalInformationDetailsHeading">
          <Translate contentKey="nylabankapiApp.userPersonalInformation.detail.title">UserPersonalInformation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.id">Id</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.id}</dd>
          <dt>
            <span id="idCardType">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.idCardType">Id Card Type</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.idCardType}</dd>
          <dt>
            <span id="idCardNumber">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.idCardNumber">Id Card Number</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.idCardNumber}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.gender}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.country">Country</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.country}</dd>
          <dt>
            <span id="dateOfBirth">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.dateOfBirth">Date Of Birth</Translate>
            </span>
          </dt>
          <dd>
            {userPersonalInformationEntity.dateOfBirth ? (
              <TextFormat value={userPersonalInformationEntity.dateOfBirth} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="phone">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.phone}</dd>
          <dt>
            <span id="countryCode">
              <Translate contentKey="nylabankapiApp.userPersonalInformation.countryCode">Country Code</Translate>
            </span>
          </dt>
          <dd>{userPersonalInformationEntity.countryCode}</dd>
          <dt>
            <Translate contentKey="nylabankapiApp.userPersonalInformation.internalUser">Internal User</Translate>
          </dt>
          <dd>{userPersonalInformationEntity.internalUser ? userPersonalInformationEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-personal-information" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-personal-information/${userPersonalInformationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserPersonalInformationDetail;
