import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './application-state.reducer';

export const ApplicationStateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const applicationStateEntity = useAppSelector(state => state.applicationState.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="applicationStateDetailsHeading">
          <Translate contentKey="nylabankapiApp.applicationState.detail.title">ApplicationState</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.applicationState.id">Id</Translate>
            </span>
          </dt>
          <dd>{applicationStateEntity.id}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="nylabankapiApp.applicationState.content">Content</Translate>
            </span>
          </dt>
          <dd>{applicationStateEntity.content}</dd>
          <dt>
            <span id="reference">
              <Translate contentKey="nylabankapiApp.applicationState.reference">Reference</Translate>
            </span>
          </dt>
          <dd>{applicationStateEntity.reference}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="nylabankapiApp.applicationState.state">State</Translate>
            </span>
          </dt>
          <dd>{applicationStateEntity.state}</dd>
          <dt>
            <Translate contentKey="nylabankapiApp.applicationState.user">User</Translate>
          </dt>
          <dd>{applicationStateEntity.user ? applicationStateEntity.user.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/application-state" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/application-state/${applicationStateEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ApplicationStateDetail;
