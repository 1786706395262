import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AddressInformation from './address-information';
import AddressInformationDetail from './address-information-detail';
import AddressInformationUpdate from './address-information-update';
import AddressInformationDeleteDialog from './address-information-delete-dialog';

const AddressInformationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AddressInformation />} />
    <Route path="new" element={<AddressInformationUpdate />} />
    <Route path=":id">
      <Route index element={<AddressInformationDetail />} />
      <Route path="edit" element={<AddressInformationUpdate />} />
      <Route path="delete" element={<AddressInformationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AddressInformationRoutes;
