export interface IAccountRepresentatives {
  id?: string;
  fullName?: string;
  position?: string;
  organizationEmail?: string;
  isCardVerified?: boolean;
  isCardUploaded?: boolean;
  isLiveFacialMatching?: boolean;
}

export const defaultValue: Readonly<IAccountRepresentatives> = {
  isCardVerified: false,
  isCardUploaded: false,
  isLiveFacialMatching: false,
};
