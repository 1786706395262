import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserAccountVerification from './user-account-verification';
import UserAccountVerificationDetail from './user-account-verification-detail';
import UserAccountVerificationUpdate from './user-account-verification-update';
import UserAccountVerificationDeleteDialog from './user-account-verification-delete-dialog';

const UserAccountVerificationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserAccountVerification />} />
    <Route path="new" element={<UserAccountVerificationUpdate />} />
    <Route path=":id">
      <Route index element={<UserAccountVerificationDetail />} />
      <Route path="edit" element={<UserAccountVerificationUpdate />} />
      <Route path="delete" element={<UserAccountVerificationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserAccountVerificationRoutes;
