import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row } from 'reactstrap';
import { Translate, ValidatedBlobField, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { createEntity, getEntity, reset, updateEntity } from './account-security.reducer';

export const AccountSecurityUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const accountSecurityEntity = useAppSelector(state => state.accountSecurity.entity);
  const loading = useAppSelector(state => state.accountSecurity.loading);
  const updating = useAppSelector(state => state.accountSecurity.updating);
  const updateSuccess = useAppSelector(state => state.accountSecurity.updateSuccess);

  const handleClose = () => {
    navigate(`/account-security${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...accountSecurityEntity,
      ...values,
      internalUser: users.find(it => it.id.toString() === values.internalUser?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...accountSecurityEntity,
          internalUser: accountSecurityEntity?.internalUser?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="nylabankapiApp.accountSecurity.home.createOrEditLabel" data-cy="AccountSecurityCreateUpdateHeading">
            <Translate contentKey="nylabankapiApp.accountSecurity.home.createOrEditLabel">Create or edit a AccountSecurity</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="account-security-id"
                  label={translate('nylabankapiApp.accountSecurity.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('nylabankapiApp.accountSecurity.enableBiometricAuthentication')}
                id="account-security-enableBiometricAuthentication"
                name="enableBiometricAuthentication"
                data-cy="enableBiometricAuthentication"
                check
                type="checkbox"
              />
              <ValidatedBlobField
                label={translate('nylabankapiApp.accountSecurity.securityQuestionOne')}
                id="account-security-securityQuestionOne"
                name="securityQuestionOne"
                data-cy="securityQuestionOne"
                openActionLabel={translate('entity.action.open')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedBlobField
                label={translate('nylabankapiApp.accountSecurity.securityQuestionTwo')}
                id="account-security-securityQuestionTwo"
                name="securityQuestionTwo"
                data-cy="securityQuestionTwo"
                openActionLabel={translate('entity.action.open')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedBlobField
                label={translate('nylabankapiApp.accountSecurity.securityQuestionThree')}
                id="account-security-securityQuestionThree"
                name="securityQuestionThree"
                data-cy="securityQuestionThree"
                openActionLabel={translate('entity.action.open')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="account-security-internalUser"
                name="internalUser"
                data-cy="internalUser"
                label={translate('nylabankapiApp.accountSecurity.internalUser')}
                type="select"
                required
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/account-security" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AccountSecurityUpdate;
