import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-account-information.reducer';

export const UserAccountInformationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userAccountInformationEntity = useAppSelector(state => state.userAccountInformation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userAccountInformationDetailsHeading">
          <Translate contentKey="nylabankapiApp.userAccountInformation.detail.title">UserAccountInformation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.userAccountInformation.id">Id</Translate>
            </span>
          </dt>
          <dd>{userAccountInformationEntity.id}</dd>
          <dt>
            <span id="accountName">
              <Translate contentKey="nylabankapiApp.userAccountInformation.accountName">Account Name</Translate>
            </span>
          </dt>
          <dd>{userAccountInformationEntity.accountName}</dd>
          <dt>
            <span id="accountClassification">
              <Translate contentKey="nylabankapiApp.userAccountInformation.accountClassification">Account Classification</Translate>
            </span>
          </dt>
          <dd>{userAccountInformationEntity.accountClassification}</dd>
          <dt>
            <span id="organizationType">
              <Translate contentKey="nylabankapiApp.userAccountInformation.organizationType">Organization Type</Translate>
            </span>
          </dt>
          <dd>{userAccountInformationEntity.organizationType}</dd>
          <dt>
            <span id="dateOfEstablishment">
              <Translate contentKey="nylabankapiApp.userAccountInformation.dateOfEstablishment">Date Of Establishment</Translate>
            </span>
          </dt>
          <dd>
            {userAccountInformationEntity.dateOfEstablishment ? (
              <TextFormat value={userAccountInformationEntity.dateOfEstablishment} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="email">
              <Translate contentKey="nylabankapiApp.userAccountInformation.email">Email</Translate>
            </span>
          </dt>
          <dd>{userAccountInformationEntity.email}</dd>
          <dt>
            <Translate contentKey="nylabankapiApp.userAccountInformation.userAccountVerification">User Account Verification</Translate>
          </dt>
          <dd>{userAccountInformationEntity.userAccountVerification ? userAccountInformationEntity.userAccountVerification.id : ''}</dd>
          <dt>
            <Translate contentKey="nylabankapiApp.userAccountInformation.addressInformation">Address Information</Translate>
          </dt>
          <dd>{userAccountInformationEntity.addressInformation ? userAccountInformationEntity.addressInformation.id : ''}</dd>
          <dt>
            <Translate contentKey="nylabankapiApp.userAccountInformation.internalUser">Internal User</Translate>
          </dt>
          <dd>{userAccountInformationEntity.internalUser ? userAccountInformationEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-account-information" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-account-information/${userAccountInformationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserAccountInformationDetail;
