import { IUser } from 'app/shared/model/user.model';
import { ApplicationStates } from 'app/shared/model/enumerations/application-states.model';

export interface IApplicationState {
  id?: string;
  content?: string | null;
  reference?: string | null;
  state?: keyof typeof ApplicationStates;
  user?: IUser;
}

export const defaultValue: Readonly<IApplicationState> = {};
