import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, byteSize, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './address-information.reducer';

export const AddressInformationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const addressInformationEntity = useAppSelector(state => state.addressInformation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="addressInformationDetailsHeading">
          <Translate contentKey="nylabankapiApp.addressInformation.detail.title">AddressInformation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.addressInformation.id">Id</Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.id}</dd>
          <dt>
            <span id="addressLineOne">
              <Translate contentKey="nylabankapiApp.addressInformation.addressLineOne">Address Line One</Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.addressLineOne}</dd>
          <dt>
            <span id="addressLineTwo">
              <Translate contentKey="nylabankapiApp.addressInformation.addressLineTwo">Address Line Two</Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.addressLineTwo}</dd>
          <dt>
            <span id="cityOrTown">
              <Translate contentKey="nylabankapiApp.addressInformation.cityOrTown">City Or Town</Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.cityOrTown}</dd>
          <dt>
            <span id="ghanaPostAddress">
              <Translate contentKey="nylabankapiApp.addressInformation.ghanaPostAddress">Ghana Post Address</Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.ghanaPostAddress}</dd>
          <dt>
            <span id="businessLocationCoordinates">
              <Translate contentKey="nylabankapiApp.addressInformation.businessLocationCoordinates">
                Business Location Coordinates
              </Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.businessLocationCoordinates}</dd>
          <dt>
            <span id="documentType">
              <Translate contentKey="nylabankapiApp.addressInformation.documentType">Document Type</Translate>
            </span>
          </dt>
          <dd>{addressInformationEntity.documentType}</dd>
          <dt>
            <span id="documentUpload">
              <Translate contentKey="nylabankapiApp.addressInformation.documentUpload">Document Upload</Translate>
            </span>
          </dt>
          <dd>
            {addressInformationEntity.documentUpload ? (
              <div>
                {addressInformationEntity.documentUploadContentType ? (
                  <a onClick={openFile(addressInformationEntity.documentUploadContentType, addressInformationEntity.documentUpload)}>
                    <img
                      src={`data:${addressInformationEntity.documentUploadContentType};base64,${addressInformationEntity.documentUpload}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {addressInformationEntity.documentUploadContentType}, {byteSize(addressInformationEntity.documentUpload)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="nylabankapiApp.addressInformation.internalUser">Internal User</Translate>
          </dt>
          <dd>{addressInformationEntity.internalUser ? addressInformationEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/address-information" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/address-information/${addressInformationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AddressInformationDetail;
