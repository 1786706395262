import { IUser } from 'app/shared/model/user.model';
import { DocumentUpload } from 'app/shared/model/enumerations/document-upload.model';

export interface IAddressInformation {
  id?: string;
  addressLineOne?: string;
  addressLineTwo?: string | null;
  cityOrTown?: string;
  ghanaPostAddress?: string;
  businessLocationCoordinates?: string;
  documentType?: keyof typeof DocumentUpload | null;
  documentUploadContentType?: string | null;
  documentUpload?: string | null;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IAddressInformation> = {};
