import { IUser } from 'app/shared/model/user.model';

export interface IProduct {
  id?: string;
  productName?: string;
  shortCode?: string;
  users?: IUser[];
}

export const defaultValue: Readonly<IProduct> = {};
