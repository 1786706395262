import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserVerifications from './user-verifications';
import UserVerificationsDetail from './user-verifications-detail';
import UserVerificationsUpdate from './user-verifications-update';
import UserVerificationsDeleteDialog from './user-verifications-delete-dialog';

const UserVerificationsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserVerifications />} />
    <Route path="new" element={<UserVerificationsUpdate />} />
    <Route path=":id">
      <Route index element={<UserVerificationsDetail />} />
      <Route path="edit" element={<UserVerificationsUpdate />} />
      <Route path="delete" element={<UserVerificationsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserVerificationsRoutes;
