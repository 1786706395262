import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AccountRepresentatives from './account-representatives';
import AccountRepresentativesDetail from './account-representatives-detail';
import AccountRepresentativesUpdate from './account-representatives-update';
import AccountRepresentativesDeleteDialog from './account-representatives-delete-dialog';

const AccountRepresentativesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AccountRepresentatives />} />
    <Route path="new" element={<AccountRepresentativesUpdate />} />
    <Route path=":id">
      <Route index element={<AccountRepresentativesDetail />} />
      <Route path="edit" element={<AccountRepresentativesUpdate />} />
      <Route path="delete" element={<AccountRepresentativesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AccountRepresentativesRoutes;
