import { IUser } from 'app/shared/model/user.model';

export interface IAccountSecurity {
  id?: string;
  enableBiometricAuthentication?: boolean;
  securityQuestionOneContentType?: string;
  securityQuestionOne?: string;
  securityQuestionTwoContentType?: string;
  securityQuestionTwo?: string;
  securityQuestionThreeContentType?: string;
  securityQuestionThree?: string;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IAccountSecurity> = {
  enableBiometricAuthentication: false,
};
