import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApplicationState from './application-state';
import ApplicationStateDetail from './application-state-detail';
import ApplicationStateUpdate from './application-state-update';
import ApplicationStateDeleteDialog from './application-state-delete-dialog';

const ApplicationStateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApplicationState />} />
    <Route path="new" element={<ApplicationStateUpdate />} />
    <Route path=":id">
      <Route index element={<ApplicationStateDetail />} />
      <Route path="edit" element={<ApplicationStateUpdate />} />
      <Route path="delete" element={<ApplicationStateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApplicationStateRoutes;
