import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, byteSize, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './kyc-information.reducer';

export const KycInformationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const kycInformationEntity = useAppSelector(state => state.kycInformation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="kycInformationDetailsHeading">
          <Translate contentKey="nylabankapiApp.kycInformation.detail.title">KycInformation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.kycInformation.id">Id</Translate>
            </span>
          </dt>
          <dd>{kycInformationEntity.id}</dd>
          <dt>
            <span id="proofOfAddress">
              <Translate contentKey="nylabankapiApp.kycInformation.proofOfAddress">Proof Of Address</Translate>
            </span>
          </dt>
          <dd>{kycInformationEntity.proofOfAddress}</dd>
          <dt>
            <span id="documentType">
              <Translate contentKey="nylabankapiApp.kycInformation.documentType">Document Type</Translate>
            </span>
          </dt>
          <dd>{kycInformationEntity.documentType}</dd>
          <dt>
            <span id="documentUpload">
              <Translate contentKey="nylabankapiApp.kycInformation.documentUpload">Document Upload</Translate>
            </span>
          </dt>
          <dd>
            {kycInformationEntity.documentUpload ? (
              <div>
                {kycInformationEntity.documentUploadContentType ? (
                  <a onClick={openFile(kycInformationEntity.documentUploadContentType, kycInformationEntity.documentUpload)}>
                    <img
                      src={`data:${kycInformationEntity.documentUploadContentType};base64,${kycInformationEntity.documentUpload}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {kycInformationEntity.documentUploadContentType}, {byteSize(kycInformationEntity.documentUpload)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="tinNumber">
              <Translate contentKey="nylabankapiApp.kycInformation.tinNumber">Tin Number</Translate>
            </span>
          </dt>
          <dd>{kycInformationEntity.tinNumber}</dd>
          <dt>
            <Translate contentKey="nylabankapiApp.kycInformation.internalUser">Internal User</Translate>
          </dt>
          <dd>{kycInformationEntity.internalUser ? kycInformationEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/kyc-information" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/kyc-information/${kycInformationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default KycInformationDetail;
