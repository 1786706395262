import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserAccountInformation from './user-account-information';
import UserAccountInformationDetail from './user-account-information-detail';
import UserAccountInformationUpdate from './user-account-information-update';
import UserAccountInformationDeleteDialog from './user-account-information-delete-dialog';

const UserAccountInformationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserAccountInformation />} />
    <Route path="new" element={<UserAccountInformationUpdate />} />
    <Route path=":id">
      <Route index element={<UserAccountInformationDetail />} />
      <Route path="edit" element={<UserAccountInformationUpdate />} />
      <Route path="delete" element={<UserAccountInformationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserAccountInformationRoutes;
