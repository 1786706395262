import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/app-user">
        <Translate contentKey="global.menu.entities.appUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-verifications">
        <Translate contentKey="global.menu.entities.userVerifications" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-personal-information">
        <Translate contentKey="global.menu.entities.userPersonalInformation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/employment-information">
        <Translate contentKey="global.menu.entities.employmentInformation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/kyc-information">
        <Translate contentKey="global.menu.entities.kycInformation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/address-information">
        <Translate contentKey="global.menu.entities.addressInformation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-account-information">
        <Translate contentKey="global.menu.entities.userAccountInformation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-account-verification">
        <Translate contentKey="global.menu.entities.userAccountVerification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/account-representatives">
        <Translate contentKey="global.menu.entities.accountRepresentatives" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/account-security">
        <Translate contentKey="global.menu.entities.accountSecurity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application-state">
        <Translate contentKey="global.menu.entities.applicationState" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization">
        <Translate contentKey="global.menu.entities.organization" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/product">
        <Translate contentKey="global.menu.entities.product" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-notification">
        <Translate contentKey="global.menu.entities.userNotification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification">
        <Translate contentKey="global.menu.entities.notification" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
