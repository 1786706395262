import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';

export interface IAppUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  gender?: keyof typeof Gender;
  email?: string;
  phone?: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string;
  country?: string;
  dateOfBirth?: dayjs.Dayjs;
  mobile?: string;
  countryCode?: string;
  user?: IUser;
}

export const defaultValue: Readonly<IAppUser> = {};
