import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IDCardTypes } from 'app/shared/model/enumerations/id-card-types.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';

export interface IUserPersonalInformation {
  id?: string;
  idCardType?: keyof typeof IDCardTypes | null;
  idCardNumber?: string;
  gender?: keyof typeof Gender;
  country?: string;
  dateOfBirth?: dayjs.Dayjs;
  phone?: string;
  countryCode?: string;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IUserPersonalInformation> = {};
