import appUser from 'app/entities/app-user/app-user.reducer';
import userVerifications from 'app/entities/user-verifications/user-verifications.reducer';
import userPersonalInformation from 'app/entities/user-personal-information/user-personal-information.reducer';
import employmentInformation from 'app/entities/employment-information/employment-information.reducer';
import kycInformation from 'app/entities/kyc-information/kyc-information.reducer';
import addressInformation from 'app/entities/address-information/address-information.reducer';
import userAccountInformation from 'app/entities/user-account-information/user-account-information.reducer';
import userAccountVerification from 'app/entities/user-account-verification/user-account-verification.reducer';
import accountRepresentatives from 'app/entities/account-representatives/account-representatives.reducer';
import accountSecurity from 'app/entities/account-security/account-security.reducer';
import applicationState from 'app/entities/application-state/application-state.reducer';
import organization from 'app/entities/organization/organization.reducer';
import product from 'app/entities/product/product.reducer';
import userNotification from 'app/entities/user-notification/user-notification.reducer';
import notification from 'app/entities/notification/notification.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  appUser,
  userVerifications,
  userPersonalInformation,
  employmentInformation,
  kycInformation,
  addressInformation,
  userAccountInformation,
  userAccountVerification,
  accountRepresentatives,
  accountSecurity,
  applicationState,
  organization,
  product,
  userNotification,
  notification,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
