import dayjs from 'dayjs';
import { IUserAccountVerification } from 'app/shared/model/user-account-verification.model';
import { IAddressInformation } from 'app/shared/model/address-information.model';
import { IUser } from 'app/shared/model/user.model';
import { AccountClassification } from 'app/shared/model/enumerations/account-classification.model';

export interface IUserAccountInformation {
  id?: string;
  accountName?: string | null;
  accountClassification?: keyof typeof AccountClassification | null;
  organizationType?: string | null;
  dateOfEstablishment?: dayjs.Dayjs;
  email?: string | null;
  userAccountVerification?: IUserAccountVerification | null;
  addressInformation?: IAddressInformation | null;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IUserAccountInformation> = {};
