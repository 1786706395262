export enum ApplicationStates {
  OTP_VERIFICATION = 'OTP_VERIFICATION',

  REGISTRATION = 'REGISTRATION',

  USER_VERIFICATION = 'USER_VERIFICATION',

  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',

  EMPLOYMENT_INFORMATION = 'EMPLOYMENT_INFORMATION',

  KYC_INFORMATION = 'KYC_INFORMATION',

  USER_ACCOUNT_INFORMATION = 'USER_ACCOUNT_INFORMATION',

  USER_ACCOUNT_VERIFICATION = 'USER_ACCOUNT_VERIFICATION',

  ACCOUNT_REPRESENTATIVES = 'ACCOUNT_REPRESENTATIVES',

  ACCOUNT_SECURITY = 'ACCOUNT_SECURITY',
}
