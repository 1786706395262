import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IOrganization {
  id?: string;
  name?: string;
  createdAt?: dayjs.Dayjs | null;
  user?: IUser;
}

export const defaultValue: Readonly<IOrganization> = {};
