import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AccountSecurity from './account-security';
import AccountSecurityDetail from './account-security-detail';
import AccountSecurityUpdate from './account-security-update';
import AccountSecurityDeleteDialog from './account-security-delete-dialog';

const AccountSecurityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AccountSecurity />} />
    <Route path="new" element={<AccountSecurityUpdate />} />
    <Route path=":id">
      <Route index element={<AccountSecurityDetail />} />
      <Route path="edit" element={<AccountSecurityUpdate />} />
      <Route path="delete" element={<AccountSecurityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AccountSecurityRoutes;
