import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getUserAccountVerifications } from 'app/entities/user-account-verification/user-account-verification.reducer';
import { getEntities as getAddressInformations } from 'app/entities/address-information/address-information.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { AccountClassification } from 'app/shared/model/enumerations/account-classification.model';
import { createEntity, getEntity, reset, updateEntity } from './user-account-information.reducer';

export const UserAccountInformationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const userAccountVerifications = useAppSelector(state => state.userAccountVerification.entities);
  const addressInformations = useAppSelector(state => state.addressInformation.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const userAccountInformationEntity = useAppSelector(state => state.userAccountInformation.entity);
  const loading = useAppSelector(state => state.userAccountInformation.loading);
  const updating = useAppSelector(state => state.userAccountInformation.updating);
  const updateSuccess = useAppSelector(state => state.userAccountInformation.updateSuccess);
  const accountClassificationValues = Object.keys(AccountClassification);

  const handleClose = () => {
    navigate(`/user-account-information${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUserAccountVerifications({}));
    dispatch(getAddressInformations({}));
    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...userAccountInformationEntity,
      ...values,
      userAccountVerification: userAccountVerifications.find(it => it.id.toString() === values.userAccountVerification?.toString()),
      addressInformation: addressInformations.find(it => it.id.toString() === values.addressInformation?.toString()),
      internalUser: users.find(it => it.id.toString() === values.internalUser?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          accountClassification: 'INDIVIDUAL',
          ...userAccountInformationEntity,
          userAccountVerification: userAccountInformationEntity?.userAccountVerification?.id,
          addressInformation: userAccountInformationEntity?.addressInformation?.id,
          internalUser: userAccountInformationEntity?.internalUser?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="nylabankapiApp.userAccountInformation.home.createOrEditLabel" data-cy="UserAccountInformationCreateUpdateHeading">
            <Translate contentKey="nylabankapiApp.userAccountInformation.home.createOrEditLabel">
              Create or edit a UserAccountInformation
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-account-information-id"
                  label={translate('nylabankapiApp.userAccountInformation.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('nylabankapiApp.userAccountInformation.accountName')}
                id="user-account-information-accountName"
                name="accountName"
                data-cy="accountName"
                type="text"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userAccountInformation.accountClassification')}
                id="user-account-information-accountClassification"
                name="accountClassification"
                data-cy="accountClassification"
                type="select"
              >
                {accountClassificationValues.map(accountClassification => (
                  <option value={accountClassification} key={accountClassification}>
                    {translate(`nylabankapiApp.AccountClassification.${accountClassification}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('nylabankapiApp.userAccountInformation.organizationType')}
                id="user-account-information-organizationType"
                name="organizationType"
                data-cy="organizationType"
                type="text"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userAccountInformation.dateOfEstablishment')}
                id="user-account-information-dateOfEstablishment"
                name="dateOfEstablishment"
                data-cy="dateOfEstablishment"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.userAccountInformation.email')}
                id="user-account-information-email"
                name="email"
                data-cy="email"
                type="text"
              />
              <ValidatedField
                id="user-account-information-userAccountVerification"
                name="userAccountVerification"
                data-cy="userAccountVerification"
                label={translate('nylabankapiApp.userAccountInformation.userAccountVerification')}
                type="select"
              >
                <option value="" key="0" />
                {userAccountVerifications
                  ? userAccountVerifications.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="user-account-information-addressInformation"
                name="addressInformation"
                data-cy="addressInformation"
                label={translate('nylabankapiApp.userAccountInformation.addressInformation')}
                type="select"
              >
                <option value="" key="0" />
                {addressInformations
                  ? addressInformations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="user-account-information-internalUser"
                name="internalUser"
                data-cy="internalUser"
                label={translate('nylabankapiApp.userAccountInformation.internalUser')}
                type="select"
                required
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-account-information" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserAccountInformationUpdate;
