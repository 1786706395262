import { IUser } from 'app/shared/model/user.model';

export interface IUserAccountVerification {
  id?: string;
  emailVerified?: boolean;
  idCardVerified?: boolean;
  mobileVerified?: boolean;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IUserAccountVerification> = {
  emailVerified: false,
  idCardVerified: false,
  mobileVerified: false,
};
