import { IUser } from 'app/shared/model/user.model';
import { DocumentUpload } from 'app/shared/model/enumerations/document-upload.model';

export interface IKycInformation {
  id?: string;
  proofOfAddress?: string;
  documentType?: keyof typeof DocumentUpload | null;
  documentUploadContentType?: string | null;
  documentUpload?: string | null;
  tinNumber?: string | null;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IKycInformation> = {};
