import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, byteSize, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './account-security.reducer';

export const AccountSecurityDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const accountSecurityEntity = useAppSelector(state => state.accountSecurity.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="accountSecurityDetailsHeading">
          <Translate contentKey="nylabankapiApp.accountSecurity.detail.title">AccountSecurity</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.accountSecurity.id">Id</Translate>
            </span>
          </dt>
          <dd>{accountSecurityEntity.id}</dd>
          <dt>
            <span id="enableBiometricAuthentication">
              <Translate contentKey="nylabankapiApp.accountSecurity.enableBiometricAuthentication">
                Enable Biometric Authentication
              </Translate>
            </span>
          </dt>
          <dd>{accountSecurityEntity.enableBiometricAuthentication ? 'true' : 'false'}</dd>
          <dt>
            <span id="securityQuestionOne">
              <Translate contentKey="nylabankapiApp.accountSecurity.securityQuestionOne">Security Question One</Translate>
            </span>
          </dt>
          <dd>
            {accountSecurityEntity.securityQuestionOne ? (
              <div>
                {accountSecurityEntity.securityQuestionOneContentType ? (
                  <a onClick={openFile(accountSecurityEntity.securityQuestionOneContentType, accountSecurityEntity.securityQuestionOne)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {accountSecurityEntity.securityQuestionOneContentType}, {byteSize(accountSecurityEntity.securityQuestionOne)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="securityQuestionTwo">
              <Translate contentKey="nylabankapiApp.accountSecurity.securityQuestionTwo">Security Question Two</Translate>
            </span>
          </dt>
          <dd>
            {accountSecurityEntity.securityQuestionTwo ? (
              <div>
                {accountSecurityEntity.securityQuestionTwoContentType ? (
                  <a onClick={openFile(accountSecurityEntity.securityQuestionTwoContentType, accountSecurityEntity.securityQuestionTwo)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {accountSecurityEntity.securityQuestionTwoContentType}, {byteSize(accountSecurityEntity.securityQuestionTwo)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="securityQuestionThree">
              <Translate contentKey="nylabankapiApp.accountSecurity.securityQuestionThree">Security Question Three</Translate>
            </span>
          </dt>
          <dd>
            {accountSecurityEntity.securityQuestionThree ? (
              <div>
                {accountSecurityEntity.securityQuestionThreeContentType ? (
                  <a
                    onClick={openFile(accountSecurityEntity.securityQuestionThreeContentType, accountSecurityEntity.securityQuestionThree)}
                  >
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {accountSecurityEntity.securityQuestionThreeContentType}, {byteSize(accountSecurityEntity.securityQuestionThree)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="nylabankapiApp.accountSecurity.internalUser">Internal User</Translate>
          </dt>
          <dd>{accountSecurityEntity.internalUser ? accountSecurityEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/account-security" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/account-security/${accountSecurityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AccountSecurityDetail;
