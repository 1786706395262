import { IUser } from 'app/shared/model/user.model';

export interface IUserVerifications {
  id?: string;
  email?: string | null;
  emailVerified?: boolean | null;
  idCard?: string | null;
  idCardVerified?: boolean | null;
  countryCode?: string | null;
  mobile?: string | null;
  mobileVerified?: boolean | null;
  internalUser?: IUser | null;
}

export const defaultValue: Readonly<IUserVerifications> = {
  emailVerified: false,
  idCardVerified: false,
  mobileVerified: false,
};
