import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AppUser from './app-user';
import UserVerifications from './user-verifications';
import UserPersonalInformation from './user-personal-information';
import EmploymentInformation from './employment-information';
import KycInformation from './kyc-information';
import AddressInformation from './address-information';
import UserAccountInformation from './user-account-information';
import UserAccountVerification from './user-account-verification';
import AccountRepresentatives from './account-representatives';
import AccountSecurity from './account-security';
import ApplicationState from './application-state';
import Organization from './organization';
import Product from './product';
import UserNotification from './user-notification';
import Notification from './notification';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="app-user/*" element={<AppUser />} />
        <Route path="user-verifications/*" element={<UserVerifications />} />
        <Route path="user-personal-information/*" element={<UserPersonalInformation />} />
        <Route path="employment-information/*" element={<EmploymentInformation />} />
        <Route path="kyc-information/*" element={<KycInformation />} />
        <Route path="address-information/*" element={<AddressInformation />} />
        <Route path="user-account-information/*" element={<UserAccountInformation />} />
        <Route path="user-account-verification/*" element={<UserAccountVerification />} />
        <Route path="account-representatives/*" element={<AccountRepresentatives />} />
        <Route path="account-security/*" element={<AccountSecurity />} />
        <Route path="application-state/*" element={<ApplicationState />} />
        <Route path="organization/*" element={<Organization />} />
        <Route path="product/*" element={<Product />} />
        <Route path="user-notification/*" element={<UserNotification />} />
        <Route path="notification/*" element={<Notification />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
