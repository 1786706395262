import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormText, Row } from 'reactstrap';
import { Translate, ValidatedBlobField, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { DocumentUpload } from 'app/shared/model/enumerations/document-upload.model';
import { createEntity, getEntity, reset, updateEntity } from './address-information.reducer';

export const AddressInformationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const addressInformationEntity = useAppSelector(state => state.addressInformation.entity);
  const loading = useAppSelector(state => state.addressInformation.loading);
  const updating = useAppSelector(state => state.addressInformation.updating);
  const updateSuccess = useAppSelector(state => state.addressInformation.updateSuccess);
  const documentUploadValues = Object.keys(DocumentUpload);

  const handleClose = () => {
    navigate(`/address-information${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...addressInformationEntity,
      ...values,
      internalUser: users.find(it => it.id.toString() === values.internalUser?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          documentType: 'UTILITY_BILL',
          ...addressInformationEntity,
          internalUser: addressInformationEntity?.internalUser?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="nylabankapiApp.addressInformation.home.createOrEditLabel" data-cy="AddressInformationCreateUpdateHeading">
            <Translate contentKey="nylabankapiApp.addressInformation.home.createOrEditLabel">Create or edit a AddressInformation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="address-information-id"
                  label={translate('nylabankapiApp.addressInformation.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('nylabankapiApp.addressInformation.addressLineOne')}
                id="address-information-addressLineOne"
                name="addressLineOne"
                data-cy="addressLineOne"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.addressInformation.addressLineTwo')}
                id="address-information-addressLineTwo"
                name="addressLineTwo"
                data-cy="addressLineTwo"
                type="text"
              />
              <ValidatedField
                label={translate('nylabankapiApp.addressInformation.cityOrTown')}
                id="address-information-cityOrTown"
                name="cityOrTown"
                data-cy="cityOrTown"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.addressInformation.ghanaPostAddress')}
                id="address-information-ghanaPostAddress"
                name="ghanaPostAddress"
                data-cy="ghanaPostAddress"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.addressInformation.businessLocationCoordinates')}
                id="address-information-businessLocationCoordinates"
                name="businessLocationCoordinates"
                data-cy="businessLocationCoordinates"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.addressInformation.documentType')}
                id="address-information-documentType"
                name="documentType"
                data-cy="documentType"
                type="select"
              >
                {documentUploadValues.map(documentUpload => (
                  <option value={documentUpload} key={documentUpload}>
                    {translate(`nylabankapiApp.DocumentUpload.${documentUpload}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedBlobField
                label={translate('nylabankapiApp.addressInformation.documentUpload')}
                id="address-information-documentUpload"
                name="documentUpload"
                data-cy="documentUpload"
                isImage
                accept="image/*"
              />
              <ValidatedField
                id="address-information-internalUser"
                name="internalUser"
                data-cy="internalUser"
                label={translate('nylabankapiApp.addressInformation.internalUser')}
                type="select"
                required
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/address-information" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AddressInformationUpdate;
