import { NotificationType } from 'app/shared/model/enumerations/notification-type.model';

export interface IUserNotification {
  id?: string;
  type?: keyof typeof NotificationType;
  mobile?: string | null;
  email?: string | null;
  content?: string | null;
  code?: string | null;
  isSent?: boolean | null;
  statusCode?: string | null;
}

export const defaultValue: Readonly<IUserNotification> = {
  isSent: false,
};
