import { IUser } from 'app/shared/model/user.model';

export interface IEmploymentInformation {
  id?: string;
  employmentStatus?: string;
  employerName?: string;
  jobTitle?: string;
  annualIncomeRange?: number;
  sourceOfFunds?: string;
  internalUser?: IUser;
}

export const defaultValue: Readonly<IEmploymentInformation> = {};
