import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import KycInformation from './kyc-information';
import KycInformationDetail from './kyc-information-detail';
import KycInformationUpdate from './kyc-information-update';
import KycInformationDeleteDialog from './kyc-information-delete-dialog';

const KycInformationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<KycInformation />} />
    <Route path="new" element={<KycInformationUpdate />} />
    <Route path=":id">
      <Route index element={<KycInformationDetail />} />
      <Route path="edit" element={<KycInformationUpdate />} />
      <Route path="delete" element={<KycInformationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default KycInformationRoutes;
