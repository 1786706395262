import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-notification.reducer';

export const UserNotificationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userNotificationEntity = useAppSelector(state => state.userNotification.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userNotificationDetailsHeading">
          <Translate contentKey="nylabankapiApp.userNotification.detail.title">UserNotification</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="nylabankapiApp.userNotification.id">Id</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.id}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="nylabankapiApp.userNotification.type">Type</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.type}</dd>
          <dt>
            <span id="mobile">
              <Translate contentKey="nylabankapiApp.userNotification.mobile">Mobile</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.mobile}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="nylabankapiApp.userNotification.email">Email</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.email}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="nylabankapiApp.userNotification.content">Content</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.content}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="nylabankapiApp.userNotification.code">Code</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.code}</dd>
          <dt>
            <span id="isSent">
              <Translate contentKey="nylabankapiApp.userNotification.isSent">Is Sent</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.isSent ? 'true' : 'false'}</dd>
          <dt>
            <span id="statusCode">
              <Translate contentKey="nylabankapiApp.userNotification.statusCode">Status Code</Translate>
            </span>
          </dt>
          <dd>{userNotificationEntity.statusCode}</dd>
        </dl>
        <Button tag={Link} to="/user-notification" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-notification/${userNotificationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserNotificationDetail;
